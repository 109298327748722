












import { Option } from "@/models/class/option.class";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class SelectResultWorkOrderStatus extends Vue {
  @Prop({ required: false, type: String, default: undefined })
  value!: string | undefined;

  @Prop({ required: false, type: Boolean, default: false })
  disabled!: boolean;

  options: Option[] = [
    {
      key: "FINISHED",
      label: this.$t("lbl_finished").toString(),
      value: "FINISHED",
    },
    {
      key: "NOT_FINISHED",
      label: this.$t("lbl_not_finished").toString(),
      value: "NOT_FINISHED",
    },
    {
      key: "CANCELLED",
      label: this.$t("lbl_cancelled").toString(),
      value: "CANCELLED",
    },
  ];
  loading = false;

  emits(e: string): void {
    this.$emit("input", e);
    this.$emit("change", e);
  }
}
