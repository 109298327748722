

























































































































































































































































































































































































import { SearchBuilder } from "@/builder";
import { debounce } from "@/helpers/debounce";
import {
  Row,
  useBlob,
  useDate,
  useDisabledFromTomorrow,
  useInvoiceAR,
  useSalesOrderReport,
} from "@/hooks";
import MNotificationVue from "@/mixins/MNotification.vue";
import { FIRST_PAGE } from "@/mixins/MQueryPage.vue";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { PAGE_SIZE_OPTIONS } from "@/models/constant/global.constant";
import { OptionModel } from "@/models/constant/interface/common.interface";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { Messages } from "@/models/enums/messages.enum";
import { SalesOrderTypeEnum } from "@/models/enums/SalesOrderType.enum";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import {
  DataWarehouse,
  ResponseWarehouse,
} from "@/models/interface/logistic.interface";
import { ResponseProduct } from "@/models/interface/product.interface";
import { SalesOrderReportResponseDto } from "@/models/interface/sales-order-report";
import {
  DeliveryOrderData,
  SalesOrderData,
} from "@/models/interface/salesOrder.interface";
import { assetsServices } from "@/services/assets.service";
import { contactServices } from "@/services/contact.service";
import { logisticServices } from "@/services/logistic.service";
import { masterServices } from "@/services/master.service";
import { productService } from "@/services/product.service";
import { salesOrderServices } from "@/services/salesorder.service";
import { ListContactDataDto } from "@interface/contact-data";
import { FormModel } from "ant-design-vue";
import moment, { Moment } from "moment";
import { VNode } from "vue";
import { Component, Mixins, Ref } from "vue-property-decorator";

type ParamDownload = {
  companyName: string;
  branchName: string;
  productCategory: string;
  productName: string;
  productNumber: string;
  soType: string;
  fromDate: string;
  soNumber: string;
  toDate: string;
  salesName: string;
  customerName: string;
  invoiceNumber: string;
  invoiceDateFrom: string;
  invoiceDateTo: string;
  doNumber: string;
  doDateFrom: string;
  doDateTo: string;
};

type TableRow = Row<SalesOrderReportResponseDto>;

@Component
export default class SalesOrderReport extends Mixins(MNotificationVue) {
  @Ref("form") form!: FormModel;
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  useDisabledFromTomorrow = useDisabledFromTomorrow;
  columnsTableDetail = {
    do: [
      {
        title: this.$t("lbl_do_date"),
        dataIndex: "deliveryDate",
        scopedSlots: { customRender: "date" },
      },
      {
        title: this.$t("lbl_do_number"),
        dataIndex: "deliveryNumber",
        scopedSlots: { customRender: "nullable" },
      },
      {
        title: this.$t("lbl_qty_do"),
        dataIndex: "qtyDO",
        scopedSlots: { customRender: "number" },
      },
    ],
    invoiceAr: [
      {
        title: this.$t("lbl_invoice_date"),
        dataIndex: "invoiceDate",
        scopedSlots: { customRender: "date" },
      },
      {
        title: this.$t("lbl_invoice_number"),
        dataIndex: "invoiceNumber",
        scopedSlots: { customRender: "nullable" },
      },
      {
        title: this.$t("lbl_qty_invoice"),
        dataIndex: "qtyInvoice",
        scopedSlots: { customRender: "number" },
      },
      {
        title: this.$t("lbl_invoice_amount"),
        dataIndex: "invoiceAmount",
        scopedSlots: { customRender: "currency" },
      },
      {
        title: this.$t("lbl_tax_invoice_number"),
        dataIndex: "taxInvoiceNumber",
        scopedSlots: { customRender: "nullable" },
      },
      {
        title: this.$t("lbl_status"),
        dataIndex: "status",
        scopedSlots: { customRender: "nullable" },
      },
    ],
    salesOrder: [
      {
        title: this.$t("lbl_part_number"),
        dataIndex: "partNumber",
        scopedSlots: { customRender: "nullable" },
      },
      {
        title: this.$t("lbl_part_name"),
        dataIndex: "equipment",
        scopedSlots: { customRender: "nullable" },
      },
      {
        title: this.$t("lbl_specification"),
        dataIndex: "spec",
        scopedSlots: { customRender: "nullable" },
      },
      {
        title: this.$t("lbl_category"),
        dataIndex: "jenis",
        scopedSlots: { customRender: "nullable" },
      },
      {
        title: this.$t("lbl_brand"),
        dataIndex: "brand",
        scopedSlots: { customRender: "nullable" },
      },
      {
        title: this.$t("lbl_type"),
        dataIndex: "type",
        scopedSlots: { customRender: "nullable" },
      },
      {
        title: this.$t("lbl_serial_number"),
        dataIndex: "serialNumber",
        scopedSlots: { customRender: "nullable" },
      },
      {
        title: this.$t("lbl_capacity"),
        dataIndex: "capacity",
        scopedSlots: { customRender: "nullable" },
      },
      {
        // description source from header
        title: this.$t("lbl_description"),
        dataIndex: "description",
        scopedSlots: { customRender: "nullable" },
      },
      {
        title: this.$t("lbl_uom"),
        dataIndex: "productUom",
        scopedSlots: { customRender: "nullable" },
      },
      {
        title: this.$t("lbl_price"),
        dataIndex: "price",
        scopedSlots: { customRender: "currency" },
      },
    ],
  };
  filterForm = {
    productCode: "",
    branch: "",
    soNumber: "",
    customerName: "",
    date: null as unknown as Moment[],
    soType: "",
    productCategory: "",
    productName: "",
    salesName: "",
    invoiceNumberId: "",
    invoiceDate: null as unknown as Moment[],
    serialNumber: "",
    doNumber: "",
    doDate: null as unknown as Moment[],
  };
  formRules = {
    date: [
      {
        required: true,
        message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
      },
    ],
    soType: [
      {
        required: true,
        message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
      },
    ],
  };
  branchOption = {
    data: [] as DataWarehouse[],
    search: "",
    fetching: true,
  };
  soTypeOption = {
    data: [] as OptionModel[],
    search: "",
    fetching: true,
  };
  customerNameOption = {
    data: [] as Array<ListContactDataDto>,
    search: "",
    fetching: true,
  };
  productCategoryOption = {
    data: [] as OptionModel[],
    search: "",
    fetching: true,
  };
  productNameOption = {
    data: [] as OptionModel[],
    search: "",
    fetching: false,
  };
  productCodeOption = {
    data: [] as Array<Option<ResponseProduct>>,
    search: "",
    fetching: false,
  };
  salesNameOption = {
    data: [] as Array<ListContactDataDto>,
    search: "",
    fetching: true,
  };
  salesOrderNumberOption = {
    data: [] as SalesOrderData[],
    search: "",
    fetching: true,
  };
  invoiceNumberOption = {
    data: [] as Option[],
    search: "",
    fetching: true,
  };
  deliveryOrderNumberOption = {
    data: [] as DeliveryOrderData[],
    search: "",
    fetching: true,
  };
  tableColumns = [
    {
      title: this.$t("lbl_date"),
      dataIndex: "date",
      scopedSlots: { customRender: "date" },
    },
    {
      title: this.$t("lbl_sales_order_no"),
      dataIndex: "salesNumber",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_qty_so"),
      dataIndex: "qtySO",
      width: 100,
      scopedSlots: { customRender: "number" },
    },
    {
      title: this.$t("lbl_branch"),
      dataIndex: "warehouseBranch",
      width: 100,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_customer_name"),
      dataIndex: "customerName",
      width: 300,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_customer_code"),
      dataIndex: "customerNo",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_sales_name"),
      dataIndex: "salesName",
      scopedSlots: { customRender: "nullable" },
    },
  ];
  dataSource: Array<TableRow> = [];
  pagination = {
    showTotal: (total: number): string =>
      this.$t("lbl_total_items", { total }).toString(),
    current: 1,
    showSizeChanger: true,
    total: 0,
    pageSize: 10,
    pageSizeOptions: PAGE_SIZE_OPTIONS,
  };
  loading = {
    download: false,
    find: false,
  };
  downloadPrintParam: ParamDownload = {
    companyName:
      this.$store.state.authStore.authData.companyName ||
      "PT. SATRIA PIRANTI PERKASA",
    branchName: "ALL",
    productCategory: "ALL",
    productName: "ALL",
    productNumber: "ALL",
    soType: "ALL",
    fromDate: "ALL",
    soNumber: "ALL",
    toDate: "ALL",
    salesName: "ALL",
    customerName: "ALL",
    invoiceNumber: "ALL",
    invoiceDateFrom: "ALL",
    invoiceDateTo: "ALL",
    doNumber: "ALL",
    doDateFrom: "ALL",
    doDateTo: "ALL",
  };
  query = new RequestQueryParams("", 0, "soDate:asc", 10);

  created(): void {
    this.getBranchList();
    this.getSoType();
    this.getCustomerName();
    this.getProductCategory();
    this.getSalesName();
    this.getSalesOrderNumber();
    this.getDeliveryOrderNumber();
    this.getInvoiceNumbers();
  }

  get isSalesOther(): boolean {
    return this.filterForm.soType === SalesOrderTypeEnum.OTHERS;
  }

  get isAssetSales(): boolean {
    return this.filterForm.soType === SalesOrderTypeEnum.ASSET_SALE;
  }

  get isProductSales(): boolean {
    return this.filterForm.soType === SalesOrderTypeEnum.PRODUCT_SALE;
  }

  handleSubmit(): void {
    const builder = new SearchBuilder();
    const { toStartDay, toEndDay } = useDate();
    this.form.validate((valid: boolean) => {
      if (!valid) {
        this.showNotifWarning("notif_validation_error");
        return;
      }

      this.pagination.current = 1;
      this.query.search = "";
      const query: string[] = [];

      if (this.filterForm.branch) {
        query.push(`branchId~${this.filterForm.branch}`);
      }

      if (this.filterForm.soNumber) {
        query.push(`soNumber~*${this.filterForm.soNumber}*`);
      }

      if (this.filterForm.doNumber) {
        query.push(`doNumber~*${this.filterForm.doNumber}*`);
      }

      if (this.filterForm.customerName) {
        query.push(`customerId~${this.filterForm.customerName}`);
      }

      if (this.filterForm.salesName) {
        query.push(`salesId~${this.filterForm.salesName}`);
      }

      if (this.filterForm.serialNumber && this.isAssetSales) {
        query.push(`assetSerialNumber~*${this.filterForm.serialNumber}*`);
      } else if (
        this.filterForm.serialNumber &&
        (this.isProductSales || this.isSalesOther)
      ) {
        query.push(`partSerialNumber~*${this.filterForm.serialNumber}*`);
      }

      this.query.productCategoryId = this.filterForm.productCategory || null;

      this.query.productId =
        this.filterForm.productName || this.filterForm.productCode || null;

      if (this.filterForm.date && this.filterForm.date.length > 0) {
        const [start, end] = this.filterForm.date;
        query.push(`soDate>=${toStartDay(start).format()}`);
        query.push(`soDate<=${toEndDay(end).format()}`);
      }

      if (
        this.filterForm.invoiceDate &&
        this.filterForm.invoiceDate.length > 0
      ) {
        const [start, end] = this.filterForm.invoiceDate;
        query.push(
          builder
            .push(["arDate", toStartDay(start).format()], {
              het: true,
            })
            .and()
            .push(["arDate", toEndDay(end).format()], {
              let: true,
            })
            .build()
        );
      }

      if (this.filterForm.soType) {
        this.query.salesType = this.filterForm.soType;
        query.push(`salesType~${this.filterForm.soType}`);
      }

      if (this.filterForm.invoiceNumberId) {
        query.push(
          builder.push(["arId", this.filterForm.invoiceNumberId]).build()
        );
      }

      if (this.filterForm.doDate && this.filterForm.doDate.length > 0) {
        const [start, end] = this.filterForm.doDate;
        query.push(
          builder
            .push(["doDate", toStartDay(start).format()], {
              het: true,
            })
            .and()
            .push(["doDate", toEndDay(end).format()], {
              let: true,
            })
            .build()
        );
      }

      this.query.search = query.join("_AND_");
      this.getSalesOrderReport();
    });
  }

  resetForm(): void {
    this.form.resetFields();
    this.filterForm.date = [];
    this.filterForm.invoiceDate = [];
  }

  getBranchList(): void {
    this.branchOption.fetching = true;
    const param = new RequestQueryParams();
    if (this.branchOption.search) {
      param.search = `name~*${this.branchOption.search}*`;
    }
    logisticServices
      .listWarehouseBranch(param, "")
      .then((res: ResponseWarehouse) => {
        this.branchOption.data = res.data;
      })
      .finally(() => (this.branchOption.fetching = false));
  }

  getSoType(): void {
    this.soTypeOption.fetching = true;
    const param: RequestQueryParamsModel = {
      name: `SALES_TYPE`,
    };
    masterServices
      .listMaster(param)
      .then(res => {
        this.soTypeOption.data = res
          .filter(item => item.value.toUpperCase() !== "RENT")
          .map(r => {
            return {
              label: r.value,
              value: r.value.replace(/\s/gi, "_").toUpperCase(),
            };
          });
      })
      .finally(() => (this.soTypeOption.fetching = false));
  }

  getCustomerName(): void {
    this.customerNameOption.fetching = true;
    const searchName: string = this.customerNameOption.search
      ? `_AND_firstName~*${this.customerNameOption.search}*_OR_lastName~*${this.customerNameOption.search}*`
      : "";
    const param: RequestQueryParamsModel = {
      page: 0,
      limit: 20,
      search: `customer~true_AND_active~true${searchName}`,
    };
    contactServices
      .listContactData(param)
      .then(res => {
        this.customerNameOption.data = res.data;
      })
      .finally(() => (this.customerNameOption.fetching = false));
  }

  getProductCategory(): void {
    this.productCategoryOption.fetching = true;
    const search = this.productCategoryOption.search
      ? `*${this.productCategoryOption.search}*`
      : "";
    const param = new RequestQueryParams();
    param.sorts = "name:asc";
    if (search) param.search = `name~${search}`;
    productService
      .listProductCategory(param)
      .then(res => {
        this.productCategoryOption.data = res.data.map(dataMap => {
          return {
            label: dataMap.name,
            value: dataMap.id,
          };
        });
      })
      .finally(() => (this.productCategoryOption.fetching = false));
  }

  getProductCode(): void {
    this.productCodeOption.fetching = true;
    const search: string[] = [];
    if (this.filterForm.productCategory) {
      search.push(`category.secureId~${this.filterForm.productCategory}`);
    }
    if (this.productCodeOption.search) {
      search.push(`code~*${this.productCodeOption.search}*`);
    }
    const params = new RequestQueryParams();
    params.search = search.join("_AND_");

    productService
      .listProduct(params)
      .then(res => {
        this.productCodeOption.data = res.data.map(d => {
          return {
            label: d.code || "",
            value: d.id,
            key: d.id,
            meta: d,
          };
        });
      })
      .finally(() => (this.productCodeOption.fetching = false));
  }

  getProduct(): void {
    this.productNameOption.fetching = true;
    const search: string[] = [];
    if (this.filterForm.productCategory) {
      search.push(`category.secureId~${this.filterForm.productCategory}`);
    }
    if (this.productNameOption.search) {
      search.push(`name~*${this.productNameOption.search}*`);
    }
    const params = {
      limit: 20,
      page: 0,
      search: search.join("_AND_"),
    } as RequestQueryParamsModel;

    productService
      .listProduct(params)
      .then(res => {
        this.productNameOption.data = res.data.map(d => {
          return {
            label: d.name,
            value: d.id,
          };
        });
      })
      .finally(() => (this.productNameOption.fetching = false));
  }

  getAssetCategory(): void {
    const search = this.productCategoryOption.search
      ? `categoryId~*${this.productCategoryOption.search}*_AND_`
      : "";
    const params = {
      limit: 20,
      page: 0,
      search: `${search}active~true_AND_book.type~COMMERCIAL`,
    };
    assetsServices.listAssetCategory(params).then(response => {
      this.productCategoryOption.data = response.data
        .map(dataMap => {
          return {
            label: dataMap.description,
            value: dataMap.id,
          };
        })
        .filter(
          (data, index, self) =>
            index === self.findIndex(t => t.value === data.value)
        );
    });
  }

  getAsset(): void {
    this.productNameOption.fetching = true;
    const searchWarehouse = this.filterForm.branch
      ? `assetLocation.warehouse.branchWarehouse.secureId~${this.filterForm.branch}_AND_`
      : "";
    const searchAsset = this.productNameOption.search
      ? `unitCode~*${this.productNameOption.search}*_AND_`
      : "";

    const params = new RequestQueryParams();
    if (searchWarehouse || searchAsset) {
      params.search = `${searchWarehouse}${searchAsset}`;
    }

    assetsServices
      .listMasterAsset(params)
      .then(res => {
        this.productNameOption.data = res.data
          .map(d => {
            return {
              label: d.unitCode,
              value: d.id,
            };
          })
          .filter(
            (data, index, self) =>
              index === self.findIndex(t => t.value === data.value)
          );
      })
      .finally(() => (this.productNameOption.fetching = false));
  }

  getSalesName(): void {
    this.salesNameOption.fetching = true;
    const searchName: string = this.salesNameOption.search
      ? `_AND_firstName~*${this.salesNameOption.search}*_OR_lastName~*${this.salesNameOption.search}*`
      : "";
    const param: RequestQueryParamsModel = {
      page: 0,
      limit: 20,
      search: `employeeData.position~*Sales*_AND_active~true${searchName}`,
    };
    contactServices
      .listContactData(param)
      .then(res => {
        this.salesNameOption.data = res.data;
      })
      .finally(() => {
        this.salesNameOption.fetching = false;
      });
  }

  getSalesOrderNumber(): void {
    this.salesOrderNumberOption.fetching = true;
    const searchDocNumber = this.salesOrderNumberOption.search
      ? `*${this.salesOrderNumberOption.search}*`
      : "";
    const param: RequestQueryParamsModel = {
      page: 0,
      limit: 20,
      search: `documentNumber~${searchDocNumber}`,
    };
    salesOrderServices
      .getListSalesOrder(param)
      .then(res => {
        this.salesOrderNumberOption.data = res.data.filter(
          (val, idx, arr) =>
            idx === arr.findIndex(a => a.salesOrderNo === val.salesOrderNo)
        );
      })
      .finally(() => (this.salesOrderNumberOption.fetching = false));
  }

  getDeliveryOrderNumber(): void {
    let params: RequestQueryParamsModel = {
      page: 0,
      limit: 20,
    };
    if (this.deliveryOrderNumberOption.search) {
      params.search = `documentNumber~*${this.deliveryOrderNumberOption.search}*`;
    }

    this.deliveryOrderNumberOption.fetching = true;
    salesOrderServices
      .getDeliveryOrderList(params)
      .then(data => {
        this.deliveryOrderNumberOption.data = data.data;
      })
      .finally(() => (this.deliveryOrderNumberOption.fetching = false));
  }

  getSalesOrderReport(): void {
    const { findAll } = useSalesOrderReport();
    const params = new RequestQueryParams();
    params.sorts = this.query.sorts;
    params.productCategoryId = this.query.productCategoryId ?? null;
    params.productId = this.query.productId ?? null;
    params.salesType = this.query.salesType ?? null;

    if (this.query.search) {
      params.search = this.query.search;
    }

    this.loading.find = true;
    findAll(params)
      .then(res => {
        this.dataSource = res.map<TableRow>((item, i) => ({
          ...item,
          key: i,
          soLineDetails:
            item.soLineDetails?.map(item2 => ({
              ...item2,

              // additional field description get value from sales order header
              description: res[i].description,
            })) ?? [],
        }));
      })
      .finally(() => {
        this.loading.find = false;
      });
  }

  getInvoiceNumbers(): void {
    const { findAll, toOptions } = useInvoiceAR();
    const searchDocNumber = this.invoiceNumberOption.search
      ? `*${this.invoiceNumberOption.search}*`
      : "";
    const params = new RequestQueryParams(`documentNumber~${searchDocNumber}`);
    this.invoiceNumberOption.fetching = true;
    findAll(params)
      .then(response => {
        this.invoiceNumberOption.data = toOptions(response.data);
      })
      .finally(() => {
        this.invoiceNumberOption.fetching = false;
      });
  }

  handleTableChange(pagination): void {
    if (pagination.current !== this.pagination.current) {
      this.pagination.current = pagination.current;
    }

    if (pagination.pageSize !== this.pagination.pageSize) {
      this.pagination.pageSize = pagination.pageSize;
      this.pagination.current = FIRST_PAGE;
    }
  }

  handleSoTypeChange(value: string, option?: VNode): void {
    if (option) {
      this.assignDownloadPrintParam("soType", value, option);
    }
    this.chooseProductList(value);
  }

  chooseProductList(soType: string): void {
    if (
      soType.toUpperCase() === "RENT" ||
      soType.toUpperCase() === "UNIT SALE"
    ) {
      this.filterForm.productName = "";
      this.filterForm.productCode = "";
      this.getAsset();
      this.getAssetCategory();
    } else if (soType.toUpperCase() === "PRODUCT SALE") {
      this.filterForm.productName = "";
      this.filterForm.productCode = "";
      this.getProduct();
      this.getProductCode();
      this.getProductCategory();
    }
  }

  handleProductCategoryChange(value: string, option: VNode): void {
    this.assignDownloadPrintParam("productCategory", value, option);
    if (this.filterForm.soType.toUpperCase() === "PRODUCT SALE") {
      this.getProduct();
      this.getProductCode();
    }
  }

  handleBranchChange(value: string, option: VNode): void {
    this.assignDownloadPrintParam("branchName", value, option);
    if (
      this.filterForm.soType.toUpperCase() === "RENT" ||
      this.filterForm.soType.toUpperCase() === "UNIT SALE"
    ) {
      this.getAsset();
    }
  }

  searchProductName(value: string): void {
    debounce(() => {
      this.productNameOption.search = value;
      this.chooseProductList(this.filterForm.soType);
    });
  }

  searchProductCode(value: string): void {
    debounce(() => {
      this.productCodeOption.search = value;
      this.chooseProductList(this.filterForm.soType);
    });
  }

  searchBranch(value: string): void {
    debounce(() => {
      this.branchOption.search = value;
      this.getBranchList();
    });
  }

  searchSoNumber(value: string): void {
    debounce(() => {
      this.salesOrderNumberOption.search = value;
      this.getSalesOrderNumber();
    });
  }

  searchDoNumber(value: string): void {
    debounce(() => {
      this.deliveryOrderNumberOption.search = value;
      this.getDeliveryOrderNumber();
    });
  }

  searchInvoiceNumber(value: string): void {
    debounce(() => {
      this.invoiceNumberOption.search = value;
      this.getInvoiceNumbers();
    });
  }

  searchCustomerName(value: string): void {
    debounce(() => {
      this.customerNameOption.search = value;
      this.getCustomerName();
    });
  }

  searchProductCategory(value: string): void {
    debounce(() => {
      this.productCategoryOption.search = value;
      this.chooseProductList(this.filterForm.soType);
    });
  }

  searchSalesName(value: string): void {
    debounce(() => {
      this.salesNameOption.search = value;
      this.getSalesName();
    });
  }

  downloadReport(): void {
    const { toStartDay, toEndDay } = useDate();
    const { toDownload } = useBlob();
    const { download } = useSalesOrderReport();
    this.form.validate((valid: boolean) => {
      if (!valid) {
        this.showNotifWarning("notif_validation_error");
        return;
      }

      this.loading.download = true;
      const params = new RequestQueryParams();
      params.search = this.query.search;
      params.params = `${this.downloadPrintParam.companyName},${
        this.downloadPrintParam.branchName
      },${this.downloadPrintParam.soNumber},${
        this.downloadPrintParam.customerName
      },${this.downloadPrintParam.soType},${
        this.downloadPrintParam.productCategory
      },${this.downloadPrintParam.productNumber},${
        this.downloadPrintParam.productName
      },${this.filterForm.serialNumber || "ALL"},${
        this.downloadPrintParam.salesName
      },${this.downloadPrintParam.fromDate},${this.downloadPrintParam.toDate},${
        this.downloadPrintParam.doNumber
      },${this.downloadPrintParam.doDateFrom},${
        this.downloadPrintParam.doDateTo
      }`;

      params.sorts = "soDate:asc";
      params.salesType = this.filterForm.soType;
      params.productCategoryId = this.filterForm.productCategory || null;
      params.productId =
        this.filterForm.productName || this.filterForm.productCode;
      params.invoiceNumberId = this.filterForm.invoiceNumberId || null;

      if (
        this.filterForm.invoiceDate &&
        this.filterForm.invoiceDate.length > 0
      ) {
        const [start, end] = this.filterForm.invoiceDate;
        params.invoiceAREnd = toEndDay(end).format();
        params.invoiceARStart = toStartDay(start).format();
      }

      download(params)
        .then(data => {
          toDownload(data, "sales_order_report.xlsx");
        })
        .finally(() => (this.loading.download = false));
    });
  }

  handleSoNumberChange(value: string, option: VNode): void {
    this.assignDownloadPrintParam("soNumber", value, option);
  }

  handleDoNumberChange(value: string, option: VNode): void {
    this.assignDownloadPrintParam("doNumber", value, option);
  }

  handleCustomerNameChange(value: string, option: VNode): void {
    this.assignDownloadPrintParam("customerName", value, option);
  }

  handleProductNameChange(value: string, option: VNode): void {
    this.assignDownloadPrintParam("productName", value, option);
  }

  handelProductCodeChange(value: string, option: VNode): void {
    this.assignDownloadPrintParam("productNumber", value, option);
  }

  handleSalesNameChange(value: string, option: VNode): void {
    this.assignDownloadPrintParam("salesName", value, option);
  }

  handleInvoiceNumberChange(value: string, option: VNode): void {
    this.assignDownloadPrintParam("invoiceNumber", value, option);
  }

  handleDatepickerChange(
    value: Moment[],
    source: "soDate" | "invoiceDate" | "doDate"
  ): void {
    const [start, end] = value;
    const startDate = start ? moment(start).format(DEFAULT_DATE_FORMAT) : "ALL";
    const endDate = end ? moment(end).format(DEFAULT_DATE_FORMAT) : "ALL";

    if (source === "soDate") {
      this.downloadPrintParam.fromDate = startDate;
      this.downloadPrintParam.toDate = endDate;
    } else if (source === "invoiceDate") {
      this.downloadPrintParam.invoiceDateFrom = startDate;
      this.downloadPrintParam.invoiceDateTo = endDate;
    } else if (source === "doDate") {
      this.downloadPrintParam.doDateFrom = startDate;
      this.downloadPrintParam.doDateTo = endDate;
    }
  }

  private assignDownloadPrintParam(
    propertyName: keyof ParamDownload,
    value: string,
    option: VNode
  ): void {
    if (value) {
      this.downloadPrintParam[propertyName] = option.key
        ?.toString()
        .split("~")[0] as string;
    } else {
      this.downloadPrintParam[propertyName] = "ALL";
    }
  }
}
