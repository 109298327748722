var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "a-card",
        { attrs: { title: _vm.$t("lbl_sales_order_report") } },
        [
          _c(
            "a-form-model",
            {
              ref: "form",
              attrs: {
                "label-align": "left",
                model: _vm.filterForm,
                rules: _vm.formRules,
                "label-col": { span: 10 },
                "wrapper-col": { span: 12 }
              },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.handleSubmit.apply(null, arguments)
                }
              }
            },
            [
              _c(
                "a-row",
                { attrs: { gutter: [16, 16] } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: _vm.$t("lbl_date"), prop: "date" } },
                        [
                          _c("a-range-picker", {
                            attrs: {
                              format: _vm.DEFAULT_DATE_FORMAT,
                              disabledDate: _vm.useDisabledFromTomorrow,
                              placeholder: [
                                _vm.$t("lbl_start_date"),
                                _vm.$t("lbl_end_date")
                              ]
                            },
                            on: {
                              change: function(e) {
                                return _vm.handleDatepickerChange(e, "soDate")
                              }
                            },
                            model: {
                              value: _vm.filterForm.date,
                              callback: function($$v) {
                                _vm.$set(_vm.filterForm, "date", $$v)
                              },
                              expression: "filterForm.date"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_sales_order_type"),
                            prop: "soType"
                          }
                        },
                        [
                          _c(
                            "a-select",
                            {
                              attrs: {
                                showSearch: true,
                                filterOption: false,
                                "dropdown-match-select-width": false
                              },
                              on: { change: _vm.handleSoTypeChange },
                              model: {
                                value: _vm.filterForm.soType,
                                callback: function($$v) {
                                  _vm.$set(_vm.filterForm, "soType", $$v)
                                },
                                expression: "filterForm.soType"
                              }
                            },
                            _vm._l(_vm.soTypeOption.data, function(
                              item,
                              index
                            ) {
                              return _c(
                                "a-select-option",
                                {
                                  key: item.label + "~" + index,
                                  attrs: { value: item.label }
                                },
                                [_vm._v(" " + _vm._s(item.label) + " ")]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: { label: _vm.$t("lbl_branch"), prop: "branch" }
                        },
                        [
                          _c(
                            "a-select",
                            {
                              attrs: {
                                showSearch: true,
                                filterOption: false,
                                "dropdown-match-select-width": false,
                                allowClear: true,
                                loading: _vm.branchOption.fetching
                              },
                              on: {
                                change: _vm.handleBranchChange,
                                search: _vm.searchBranch
                              },
                              model: {
                                value: _vm.filterForm.branch,
                                callback: function($$v) {
                                  _vm.$set(_vm.filterForm, "branch", $$v)
                                },
                                expression: "filterForm.branch"
                              }
                            },
                            _vm._l(_vm.branchOption.data, function(
                              item,
                              index
                            ) {
                              return _c(
                                "a-select-option",
                                {
                                  key: item.name + "~" + index,
                                  attrs: { value: item.id }
                                },
                                [_vm._v(_vm._s(item.name))]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_sales_order_no"),
                            prop: "soNumber"
                          }
                        },
                        [
                          _c(
                            "a-select",
                            {
                              attrs: {
                                showSearch: true,
                                filterOption: false,
                                allowClear: true,
                                loading: _vm.salesOrderNumberOption.fetching,
                                "dropdown-match-select-width": false
                              },
                              on: {
                                search: _vm.searchSoNumber,
                                change: _vm.handleSoNumberChange
                              },
                              model: {
                                value: _vm.filterForm.soNumber,
                                callback: function($$v) {
                                  _vm.$set(_vm.filterForm, "soNumber", $$v)
                                },
                                expression: "filterForm.soNumber"
                              }
                            },
                            _vm._l(_vm.salesOrderNumberOption.data, function(
                              item,
                              index
                            ) {
                              return _c(
                                "a-select-option",
                                {
                                  key: item.salesOrderNo + "~" + index,
                                  attrs: { value: item.salesOrderNo }
                                },
                                [_vm._v(_vm._s(item.salesOrderNo))]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.isSalesOther,
                              expression: "!isSalesOther"
                            }
                          ],
                          attrs: {
                            label: _vm.$t("lbl_delivery_order"),
                            prop: "doNumber"
                          }
                        },
                        [
                          _c(
                            "a-select",
                            {
                              attrs: {
                                showSearch: true,
                                "filter-option": false,
                                allowClear: true,
                                loading: _vm.deliveryOrderNumberOption.fetching,
                                "dropdown-match-select-width": false
                              },
                              on: {
                                search: _vm.searchDoNumber,
                                change: _vm.handleDoNumberChange
                              },
                              model: {
                                value: _vm.filterForm.doNumber,
                                callback: function($$v) {
                                  _vm.$set(_vm.filterForm, "doNumber", $$v)
                                },
                                expression: "filterForm.doNumber"
                              }
                            },
                            _vm._l(_vm.deliveryOrderNumberOption.data, function(
                              data,
                              index
                            ) {
                              return _c(
                                "a-select-option",
                                {
                                  key: data.documentNumber + "~" + index,
                                  attrs: { value: data.documentNumber }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s("" + data.documentNumber) + " "
                                  )
                                ]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_customer_name"),
                            prop: "customerName"
                          }
                        },
                        [
                          _c(
                            "a-select",
                            {
                              attrs: {
                                showSearch: true,
                                filterOption: false,
                                allowClear: true,
                                loading: _vm.customerNameOption.fetching,
                                "dropdown-match-select-width": false
                              },
                              on: {
                                search: _vm.searchCustomerName,
                                change: _vm.handleCustomerNameChange
                              },
                              model: {
                                value: _vm.filterForm.customerName,
                                callback: function($$v) {
                                  _vm.$set(_vm.filterForm, "customerName", $$v)
                                },
                                expression: "filterForm.customerName"
                              }
                            },
                            _vm._l(_vm.customerNameOption.data, function(
                              item,
                              index
                            ) {
                              return _c(
                                "a-select-option",
                                {
                                  key: item.fullName + "~" + index,
                                  attrs: { value: item.id }
                                },
                                [_vm._v(_vm._s(item.fullName))]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_part_category"),
                            prop: "productCategory"
                          }
                        },
                        [
                          _c(
                            "a-select",
                            {
                              attrs: {
                                showSearch: true,
                                filterOption: false,
                                allowClear: true,
                                loading: _vm.productCategoryOption.fetching,
                                "dropdown-match-select-width": false
                              },
                              on: {
                                change: _vm.handleProductCategoryChange,
                                search: _vm.searchProductCategory
                              },
                              model: {
                                value: _vm.filterForm.productCategory,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.filterForm,
                                    "productCategory",
                                    $$v
                                  )
                                },
                                expression: "filterForm.productCategory"
                              }
                            },
                            _vm._l(_vm.productCategoryOption.data, function(
                              item,
                              index
                            ) {
                              return _c(
                                "a-select-option",
                                {
                                  key: item.label + "~" + index,
                                  attrs: { value: item.value }
                                },
                                [_vm._v(_vm._s(item.label))]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_part_name"),
                            prop: "productName"
                          }
                        },
                        [
                          _c(
                            "a-select",
                            {
                              attrs: {
                                showSearch: true,
                                filterOption: false,
                                loading: _vm.productNameOption.fetching,
                                allowClear: true,
                                "dropdown-match-select-width": false
                              },
                              on: {
                                search: _vm.searchProductName,
                                change: _vm.handleProductNameChange
                              },
                              model: {
                                value: _vm.filterForm.productName,
                                callback: function($$v) {
                                  _vm.$set(_vm.filterForm, "productName", $$v)
                                },
                                expression: "filterForm.productName"
                              }
                            },
                            _vm._l(_vm.productNameOption.data, function(
                              item,
                              index
                            ) {
                              return _c(
                                "a-select-option",
                                {
                                  key: item.label + "~" + index,
                                  attrs: { value: item.value }
                                },
                                [_vm._v(_vm._s(item.label))]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_part_number"),
                            prop: "productCode"
                          }
                        },
                        [
                          _c(
                            "a-select",
                            {
                              attrs: {
                                showSearch: true,
                                "dropdown-match-select-width": false,
                                filterOption: false,
                                loading: _vm.productCodeOption.fetching,
                                allowClear: true
                              },
                              on: {
                                search: _vm.searchProductCode,
                                change: _vm.handelProductCodeChange
                              },
                              model: {
                                value: _vm.filterForm.productCode,
                                callback: function($$v) {
                                  _vm.$set(_vm.filterForm, "productCode", $$v)
                                },
                                expression: "filterForm.productCode"
                              }
                            },
                            _vm._l(_vm.productCodeOption.data, function(
                              item,
                              index
                            ) {
                              return _c(
                                "a-select-option",
                                {
                                  key: item.label + "~" + index,
                                  attrs: { value: item.value }
                                },
                                [_vm._v(_vm._s(item.label))]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.isSalesOther,
                              expression: "!isSalesOther"
                            }
                          ],
                          attrs: {
                            label: _vm.$t("lbl_do_date"),
                            prop: "doDate"
                          }
                        },
                        [
                          _c("a-range-picker", {
                            attrs: {
                              format: _vm.DEFAULT_DATE_FORMAT,
                              disabledDate: _vm.useDisabledFromTomorrow,
                              placeholder: [
                                _vm.$t("lbl_start_date"),
                                _vm.$t("lbl_end_date")
                              ]
                            },
                            on: {
                              change: function(e) {
                                return _vm.handleDatepickerChange(e, "doDate")
                              }
                            },
                            model: {
                              value: _vm.filterForm.doDate,
                              callback: function($$v) {
                                _vm.$set(_vm.filterForm, "doDate", $$v)
                              },
                              expression: "filterForm.doDate"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_serial_number"),
                            prop: "serialNumber"
                          }
                        },
                        [
                          _c("a-input", {
                            attrs: { "allow-clear": "" },
                            model: {
                              value: _vm.filterForm.serialNumber,
                              callback: function($$v) {
                                _vm.$set(_vm.filterForm, "serialNumber", $$v)
                              },
                              expression: "filterForm.serialNumber"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_sales_name"),
                            prop: "salesName"
                          }
                        },
                        [
                          _c(
                            "a-select",
                            {
                              attrs: {
                                showSearch: true,
                                filterOption: false,
                                loading: _vm.salesNameOption.fetching,
                                "dropdown-match-select-width": false,
                                allowClear: true
                              },
                              on: {
                                search: _vm.searchSalesName,
                                change: _vm.handleSalesNameChange
                              },
                              model: {
                                value: _vm.filterForm.salesName,
                                callback: function($$v) {
                                  _vm.$set(_vm.filterForm, "salesName", $$v)
                                },
                                expression: "filterForm.salesName"
                              }
                            },
                            _vm._l(_vm.salesNameOption.data, function(
                              item,
                              index
                            ) {
                              return _c(
                                "a-select-option",
                                {
                                  key: item.fullName + "~" + index,
                                  attrs: { value: item.id }
                                },
                                [_vm._v(_vm._s(item.fullName))]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_invoice_number"),
                            prop: "invoiceNumberId"
                          }
                        },
                        [
                          _c(
                            "a-select",
                            {
                              attrs: {
                                "show-search": "",
                                "allow-clear": "",
                                loading: _vm.invoiceNumberOption.fetching,
                                "dropdown-match-select-width": false,
                                "filter-option": false
                              },
                              on: {
                                search: _vm.searchInvoiceNumber,
                                change: _vm.handleInvoiceNumberChange
                              },
                              model: {
                                value: _vm.filterForm.invoiceNumberId,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.filterForm,
                                    "invoiceNumberId",
                                    $$v
                                  )
                                },
                                expression: "filterForm.invoiceNumberId"
                              }
                            },
                            _vm._l(_vm.invoiceNumberOption.data, function(
                              item,
                              index
                            ) {
                              return _c(
                                "a-select-option",
                                {
                                  key: item.meta
                                    ? item.meta.documentNumber + "~" + index
                                    : item.key,
                                  attrs: { value: item.value }
                                },
                                [_vm._v(" " + _vm._s(item.label) + " ")]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_invoice_date"),
                            prop: "invoiceDate"
                          }
                        },
                        [
                          _c("a-range-picker", {
                            attrs: {
                              format: _vm.DEFAULT_DATE_FORMAT,
                              placeholder: [
                                _vm.$t("lbl_start_date"),
                                _vm.$t("lbl_end_date")
                              ],
                              disabledDate: _vm.useDisabledFromTomorrow
                            },
                            on: {
                              change: function(e) {
                                return _vm.handleDatepickerChange(
                                  e,
                                  "invoiceDate"
                                )
                              }
                            },
                            model: {
                              value: _vm.filterForm.invoiceDate,
                              callback: function($$v) {
                                _vm.$set(_vm.filterForm, "invoiceDate", $$v)
                              },
                              expression: "filterForm.invoiceDate"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: [16, 16] } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-space",
                        [
                          _c("a-button", { on: { click: _vm.resetForm } }, [
                            _vm._v(_vm._s(_vm.$t("lbl_reset")))
                          ]),
                          _c(
                            "a-button",
                            {
                              attrs: {
                                type: "primary",
                                loading: _vm.loading.find,
                                "html-type": "submit"
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("lbl_find")) + " ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("a-table", {
            attrs: {
              "data-source": _vm.dataSource,
              columns: _vm.tableColumns,
              loading: _vm.loading.find,
              pagination: _vm.pagination,
              scroll: { x: 1000 },
              size: "small"
            },
            on: { change: _vm.handleTableChange },
            scopedSlots: _vm._u([
              {
                key: "date",
                fn: function(text) {
                  return _c("span", {}, [_vm._v(_vm._s(_vm._f("date")(text)))])
                }
              },
              {
                key: "nullable",
                fn: function(text) {
                  return _c("span", {}, [_vm._v(_vm._s(text || "-"))])
                }
              },
              {
                key: "number",
                fn: function(text) {
                  return _c("span", {}, [_vm._v(_vm._s(_vm._f("qty")(text)))])
                }
              },
              {
                key: "expandedRowRender",
                fn: function(record) {
                  return [
                    _c("a-divider", { attrs: { orientation: "left" } }, [
                      _vm._v(" " + _vm._s(_vm.$t("lbl_sales_order")) + " ")
                    ]),
                    _c("a-table", {
                      attrs: {
                        columns: _vm.columnsTableDetail.salesOrder,
                        "data-source": record.soLineDetails,
                        "row-key": function(row, i) {
                          return i
                        },
                        pagination: {
                          showTotal: function(total) {
                            return _vm.$t("lbl_total_items", { total: total })
                          }
                        },
                        scroll: { x: 1000 },
                        size: "small"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "date",
                            fn: function(text) {
                              return _c("span", {}, [
                                _vm._v(_vm._s(_vm._f("date")(text)))
                              ])
                            }
                          },
                          {
                            key: "nullable",
                            fn: function(text) {
                              return _c("span", {}, [
                                _vm._v(_vm._s(text || "-"))
                              ])
                            }
                          },
                          {
                            key: "number",
                            fn: function(text) {
                              return _c("span", {}, [
                                _vm._v(_vm._s(_vm._f("qty")(text)))
                              ])
                            }
                          },
                          {
                            key: "currency",
                            fn: function(text) {
                              return _c("span", {}, [
                                _vm._v(
                                  " " + _vm._s(_vm._f("currency")(text)) + " "
                                )
                              ])
                            }
                          }
                        ],
                        null,
                        true
                      )
                    }),
                    _c("a-divider", { attrs: { orientation: "left" } }, [
                      _vm._v(" " + _vm._s(_vm.$t("lbl_delivery_order")) + " ")
                    ]),
                    _c("a-table", {
                      attrs: {
                        columns: _vm.columnsTableDetail.do,
                        "data-source": record.deliveryOrderDetails,
                        "row-key": function(row, i) {
                          return row.deliveryNumber
                        },
                        size: "small",
                        pagination: {
                          showTotal: function(total) {
                            return _vm.$t("lbl_total_items", { total: total })
                          }
                        },
                        scroll: { x: 1000 }
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "date",
                            fn: function(text) {
                              return _c("span", {}, [
                                _vm._v(_vm._s(_vm._f("date")(text)))
                              ])
                            }
                          },
                          {
                            key: "nullable",
                            fn: function(text) {
                              return _c("span", {}, [
                                _vm._v(_vm._s(text || "-"))
                              ])
                            }
                          },
                          {
                            key: "number",
                            fn: function(text) {
                              return _c("span", {}, [
                                _vm._v(_vm._s(_vm._f("qty")(text)))
                              ])
                            }
                          }
                        ],
                        null,
                        true
                      )
                    }),
                    _c("a-divider", { attrs: { orientation: "left" } }, [
                      _vm._v(" " + _vm._s(_vm.$t("lbl_invoice_ar")) + " ")
                    ]),
                    _c("a-table", {
                      attrs: {
                        columns: _vm.columnsTableDetail.invoiceAr,
                        "data-source": record.invoiceArDetails,
                        "row-key": function(row, i) {
                          return row.invoiceNumber
                        },
                        pagination: {
                          showTotal: function(total) {
                            return _vm.$t("lbl_total_items", { total: total })
                          }
                        },
                        size: "small",
                        scroll: { x: 1000 }
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "date",
                            fn: function(text) {
                              return _c("span", {}, [
                                _vm._v(_vm._s(_vm._f("date")(text)))
                              ])
                            }
                          },
                          {
                            key: "nullable",
                            fn: function(text) {
                              return _c("span", {}, [
                                _vm._v(_vm._s(text || "-"))
                              ])
                            }
                          },
                          {
                            key: "number",
                            fn: function(text) {
                              return _c("span", {}, [
                                _vm._v(_vm._s(_vm._f("qty")(text)))
                              ])
                            }
                          },
                          {
                            key: "currency",
                            fn: function(text) {
                              return _c("span", {}, [
                                _vm._v(
                                  " " + _vm._s(_vm._f("currency")(text)) + " "
                                )
                              ])
                            }
                          }
                        ],
                        null,
                        true
                      )
                    })
                  ]
                }
              }
            ])
          }),
          _c(
            "div",
            { staticClass: "mt-2 text-right" },
            [
              _c(
                "a-button",
                {
                  attrs: {
                    type: "primary",
                    icon: "download",
                    loading: _vm.loading.download
                  },
                  on: { click: _vm.downloadReport }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_download")) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }